var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-head-table',{attrs:{"title":_vm.$t('distributions.title'),"button-title":_vm.$t('distributions.button.snapshot.create'),"button-icon":"ios-qr-scanner","title-icon":"ios-git-network"},on:{"onCreateElem":_vm.createSnapshot}}),_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('distributions.label.description')))])]),_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"snapshots/getIssuerSnapshots","url-params":_vm.urlParams,"fields":_vm.tableFields,"without-search":""},scopedSlots:_vm._u([{key:"secondaryControls",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat mr-2"},on:{"click":function($event){return _vm.editSnapshot(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create"}),_vm._v(" "+_vm._s(_vm.$t(rowData.field.cta.edit))+" ")]),_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"variant":"default btn-xs md-btn-flat mr-2","title":"View"},on:{"click":function($event){return _vm.navToSnapshotPage( rowData.item.name, rowData.item.id )}}},[_c('i',{staticClass:"ion ion-md-eye"}),_vm._v(" "+_vm._s(_vm.$t(rowData.field.cta.view))+" ")])]}},{key:"date",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("dateAndTimeFilter")(rowData.item.date))+" ")])]}},{key:"status",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.statusLabels[rowData.item.status]))+" ")]}},{key:"primaryControls",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"disabled":rowData.item.distributeCreated,"variant":"btn btn-primary btn-sm mr-2"},on:{"click":function($event){return _vm.openDistributionsModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-ios-git-network"}),_vm._v(" "+_vm._s(_vm.$t(rowData.field.cta.generate))+" ")]),_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"disabled":!rowData.item.distributeCreated || rowData.item.status === 'distributed',"variant":"btn btn-primary btn-sm mr-2"},on:{"click":function($event){return _vm.onConfirmDistribution(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-checkmark"}),_vm._v(" "+_vm._s(_vm.$t(rowData.field.cta.confirm))+" ")])]}}])}),_c('snapshot-modal',{ref:"CpSnapshotModal",attrs:{"snapshot":_vm.snapshotData,"title-data":_vm.titleData},on:{"onSubmit":_vm.saveSnapshot}}),_c('generate-distribution-modal',{ref:"generateDistributionModal",attrs:{"distributions-modal-state":_vm.distributionsModalState}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }