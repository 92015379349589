<template>
  <div>
    <cp-head-table
      :title="$t('distributions.title')"
      :button-title="$t('distributions.button.snapshot.create')"
      button-icon="ios-qr-scanner"
      title-icon="ios-git-network"
      @onCreateElem="createSnapshot"
    />
    <div>
      <p>{{ $t('distributions.label.description') }}</p>
    </div>
    <cp-table
      ref="cpTable"
      get-data-action="snapshots/getIssuerSnapshots"
      :url-params="urlParams"
      :fields="tableFields"
      without-search
    >
      <template
        slot="secondaryControls"
        slot-scope="{rowData}"
      >
        <b-btn
          v-b-tooltip.hover="true"
          variant="default edit-operator-btn btn-xs md-btn-flat mr-2"
          @click="editSnapshot(rowData.item)"
        >
          <i class="ion ion-md-create" />
          {{ $t(rowData.field.cta.edit) }}
        </b-btn>
        <b-btn
          v-b-tooltip.hover="true"
          variant="default btn-xs md-btn-flat mr-2"
          title="View"
          @click="navToSnapshotPage( rowData.item.name, rowData.item.id )"
        >
          <i class="ion ion-md-eye" />
          {{ $t(rowData.field.cta.view) }}
        </b-btn>
      </template>
      <template
        slot="date"
        slot-scope="{rowData}"
      >
        <div>
          {{ rowData.item.date | dateAndTimeFilter }}
        </div>
      </template>
      <template
        slot="status"
        slot-scope="{rowData}"
      >
        {{ $t(statusLabels[rowData.item.status]) }}
      </template>
      <template
        slot="primaryControls"
        slot-scope="{rowData}"
      >
        <b-btn
          v-b-tooltip.hover="true"
          :disabled="rowData.item.distributeCreated"
          variant="btn btn-primary btn-sm mr-2"
          @click="openDistributionsModal(rowData.item)"
        >
          <i class="ion ion-ios-git-network" />
          {{ $t(rowData.field.cta.generate) }}
        </b-btn>
        <b-btn
          v-b-tooltip.hover="true"
          :disabled="!rowData.item.distributeCreated || rowData.item.status === 'distributed'"
          variant="btn btn-primary btn-sm mr-2"
          @click="onConfirmDistribution(rowData.item)"
        >
          <i class="ion ion-md-checkmark" />
          {{ $t(rowData.field.cta.confirm) }}
        </b-btn>
      </template>
    </cp-table>
    <snapshot-modal
      ref="CpSnapshotModal"
      :snapshot="snapshotData"
      :title-data="titleData"
      @onSubmit="saveSnapshot"
    />
    <generate-distribution-modal
      ref="generateDistributionModal"
      :distributions-modal-state="distributionsModalState"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import CpTable from '~/components/shared/cp-table';
import GenerateDistributionModal from './components/generate-distribution-modal';
import SnapshotModal from './components/snapshot-modal';
import CpHeadTable from '~/components/common/head';
import { tableFields, statusLabels } from './fields';
import routeNames from '~/utilities/routes';

export default {
  name: 'Distributions',
  metaInfo: {
    title: 'Distributions',
  },
  components: {
    CpTable,
    CpHeadTable,
    GenerateDistributionModal,
    SnapshotModal,
  },
  data() {
    return {
      urlParams: {
        issuerId: this.$route.params.idIssuer,
        tokenId: this.$route.params.tokenId,
      },
      distributionsModalState: {},
      titleData: {
        type: 'generate',
        label: this.$t('distributions.snapshotModal.title.generate'),
        icon: 'ios-qr-scanner',
      },
      snapshotData: {},
      routeNames,
      tableFields,
      statusLabels,
      idIssuer: this.$route.params.idIssuer,
    };
  },
  computed: {
    stateFilters() {
      return JSON.parse(JSON.stringify(this.getHoldingInvestorFilters));
    },
  },
  watch: {
    $route({ params }) {
      this.urlParams.tokenId = params.tokenId;
    },
  },
  methods: {
    ...mapActions('distributions', ['postDistribution', 'confirmDistribution']),
    ...mapActions('snapshots', ['patchSnapshotsById', 'postSnapshotsById']),

    toViewPage({ id: idHolder }) {
      // TODO make more pretty on distributions refactoring
      this.$router.push(this.routeNames.viewDetails(this.idIssuer, this.$route.params.tokenId, idHolder));
    },

    openDistributionsModal(modalState) {
      this.distributionsModalState = modalState;
      this.$refs.generateDistributionModal.$refs.generateDistribution.show();
    },

    showSnapshotModal() {
      this.$refs.CpSnapshotModal.$refs.snapshotModal.show();
    },

    closeSnapshotModal() {
      this.$refs.CpSnapshotModal.$refs.snapshotModal.hide();
    },

    createSnapshot() {
      this.titleData.type = 'generate';
      this.titleData.label = this.$t('distributions.snapshotModal.title.generate');
      const name = this.$t('distributions.snapshotModal.input.name.defaultValue', [moment().format('LLL')]);
      this.snapshotData = {
        name,
        date: moment().format(),
        issuerId: this.idIssuer,
        tokenId: this.$route.params.tokenId,
      };
      this.showSnapshotModal();
    },

    navToSnapshotPage(name, id) {
      name = name.trim().split(' ').join('_');
      this.$router.push(this.routeNames.distributionSnapshots(this.idIssuer, this.$route.params.tokenId, id, name));
    },

    onConfirmDistribution(snapshot) {
      snapshot.tokenId = this.$route.params.tokenId;
      this.confirmDistribution(snapshot).then(() => {
        this.$refs.cpTable.updateTableData();
      });
    },

    editSnapshot(snapshot) {
      this.snapshotData = snapshot;
      this.snapshotData.tokenId = this.$route.params.tokenId;
      this.titleData.type = 'edit';
      this.titleData.label = this.$t('distributions.snapshotModal.title.edit');
      this.showSnapshotModal();
    },

    saveSnapshot({ action, snapshot }) {
      if (action === 'post' && !snapshot.name) {
        snapshot.name = `Snapshot at ${moment().format('LLL')}`;
      }
      this[`${action}SnapshotsById`](snapshot).then(() => {
        this.$refs.cpTable.updateTableData();
      }).finally(() => {
        this.closeSnapshotModal();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
