export const statusLabels = {
  distributed: 'distributions.status.distributed',
  none: 'distributions.status.none',
};

export const tableFields = [
  { key: 'name', i18nKey: 'distributions.label.table.name', tdClass: 'align-middle' },
  { key: 'date', i18nKey: 'distributions.label.table.date', tdClass: 'align-middle' },
  {
    key: 'status',
    i18nKey: 'distributions.label.table.status',
    condition: { distributed: 'Distributed', none: 'None' },
    tdClass: 'align-middle',
  },
  {
    key: 'secondaryControls',
    cta: {
      edit: 'distributions.button.snapshot.edit',
      view: 'distributions.button.snapshot.view',
      download: 'distributions.button.csv.download',
    },
    label: '',
    tdClass: 'align-middle',
  },
  {
    key: 'primaryControls',
    cta: {
      generate: 'distributions.button.distribution.generate',
      confirm: 'distributions.button.distribution.confirm',
    },
    label: '',
    tdClass: 'align-middle',
  },
];
export const innerTableFields = [
  { key: 'name', i18nKey: 'distributionDetail.label.table.name', tdClass: 'align-middle' },
  { key: 'email', i18nKey: 'distributionDetail.label.table.email', tdClass: 'align-middle' },
  { key: 'tokens', i18nKey: 'distributionDetail.label.table.tokens', sortable: true },
  { key: 'tax-forms', i18nKey: 'distributionDetail.label.table.taxForms', tdClass: 'align-middle' },
  { key: 'payout-method', i18nKey: 'distributionDetail.label.table.payoutMethod', tdClass: 'align-middle' },
];
