<template>
  <cp-general-modal
    ref="generateDistribution"
    :title="$t('distributions.generateDistributionModal.title')"
    title-icon="ios-git-network"
    :ok-text="isLoading ? $t('distributions.generateDistributionModal.button.loading') : $t('distributions.generateDistributionModal.button.ok')"
    :ok-disabled="!isValid || isLoading"
    @onOk="createDistribution"
    @onCancel="distributedAmount = ''"
  >
    <cp-input
      v-model="distributedAmount"
      :label="amountInputLabel"
      input-type="number"
    />
    <cp-select
      v-model="selectedSecondary"
      :label="$t('distributions.generateDistributionModal.input.distributionModel')"
      :options="types"
    />
    <cp-select
      v-model="selectedThird"
      :label="$t('distributions.generateDistributionModal.input.distributionMethod')"
      :options="methodOptions"
    />
  </cp-general-modal>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

export default {
  name: 'GenerateDistributionModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
  },
  props: {
    distributionsModalState: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      distributedAmount: '',
      selectedSecondary: 'total',
      isLoading: false,
      types: [
        { value: 'total', i18nKey: 'distributions.generateDistributionModal.option.distributionModel.total' },
        { value: 'perToken', i18nKey: 'distributions.generateDistributionModal.option.distributionModel.perToken' },
      ],
      selectedThird: 'bankAccount',
      methodOptions: [
        { value: 'bankAccount', i18nKey: 'distributions.generateDistributionModal.option.distributionMethod.bankAccount' },
        { value: 'directToWallet', i18nKey: 'distributions.generateDistributionModal.option.distributionMethod.directToWallet' },
      ],
    };
  },
  computed: {
    ...mapState('global', ['issuerMainCurrencyIdentifier']),
    amountInputLabel() {
      const type = this.types.find(t => t.value === this.selectedSecondary);
      return this.$t(
        'distributions.generateDistributionModal.input.distributedAmount',
        {
          distributionModel: this.$t(type.i18nKey),
          currencySymbol: this.$options.filters.currencySymbol(this.issuerMainCurrencyIdentifier),
        },
      );
    },
    isValid() {
      return this.distributedAmount > 0;
    },
  },

  methods: {
    ...mapActions('distributions', ['postDistribution']),
    ...mapActions('snapshotsRecords', ['getDistributionDownloadUrl']),

    createDistribution() {
      this.isLoading = true;

      const localState = this.distributionsModalState;
      const regex = /snapshot_/i;
      localState.snapshotName = localState.snapshotName
        ? `distribution_${localState.snapshotName.trim().split(' ').join('_').replace(regex, '')}` : 'distribution';
      localState.body = {
        type: this.selectedSecondary || 'perToken',
        method: this.selectedThird,
        amount: +this.distributedAmount,
        tokenId: this.$route.params.tokenId,
      };
      this.postDistribution(localState).then(({ res }) => {
        this.$parent.$refs.cpTable.updateTableData();
        if (res && res.data && res.status < 400) {
            this.exportCsv(localState.id).finally(() => {
              this.isLoading = false;
              this.distributedAmount = 0;
              this.$refs.generateDistribution.hide();
          });
        } else {
          this.isLoading = false;
        }
      });
    },
    exportCsv(snapshotId) {
      return this.getDistributionDownloadUrl(
        {
          issuerId: this.$route.params.idIssuer,
          tokenId: this.$route.params.tokenId,
          snapshotId,
        },
      );
    },
  },
};
</script>
